<template>
  <div class="absolute inset-0" v-if="interieurObjectenShown">
    <div
      v-for="(object, index) in interieurObjecten"
      :key="index"
      class="absolute border-2 border-gray-300 rounded-sm bg-gray-300/70 transition"
      :style="{
        width: `${(object.length / panelSpace('horizontal')) * 100}%`,
        height: `${(object.width / panelSpace('vertical')) * 100}%`,
        right: `${(object.distanceToBackWall / panelSpace('horizontal')) *
          100}%`,
        top: `${(object.distanceToRightWall / panelSpace('vertical')) * 100}%`,
        transform: `rotate(${object.rotation || 0}deg)`,
      }"
    >
      <div class="px-1 py-0.5" v-if="object.showLabel">
        <div class="text-xs text-gray-500 font-semibold">{{ object.name }}</div>
        <div class="text-xs text-gray-500 font-semibold">
          {{ object.length }} x {{ object.width }} cm
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from "vuex-pathify";

export default {
  computed: {
    interieurObjecten: get("interieurObjecten"),
    panelSpace: get("panelSpace"),
    interieurObjectenShown: get("interieurObjectenShown"),
  },
};
</script>
