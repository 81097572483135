var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.interieurObjectenShown)?_c('div',{staticClass:"absolute inset-0"},_vm._l((_vm.interieurObjecten),function(object,index){return _c('div',{key:index,staticClass:"absolute border-2 border-gray-300 rounded-sm bg-gray-300/70 transition",style:({
      width: `${(object.length / _vm.panelSpace('horizontal')) * 100}%`,
      height: `${(object.width / _vm.panelSpace('vertical')) * 100}%`,
      right: `${(object.distanceToBackWall / _vm.panelSpace('horizontal')) *
        100}%`,
      top: `${(object.distanceToRightWall / _vm.panelSpace('vertical')) * 100}%`,
      transform: `rotate(${object.rotation || 0}deg)`,
    })},[(object.showLabel)?_c('div',{staticClass:"px-1 py-0.5"},[_c('div',{staticClass:"text-xs text-gray-500 font-semibold"},[_vm._v(_vm._s(object.name))]),_c('div',{staticClass:"text-xs text-gray-500 font-semibold"},[_vm._v(" "+_vm._s(object.length)+" x "+_vm._s(object.width)+" cm ")])]):_vm._e()])}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }