var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-1 max-w-7xl"},[_c('div',{staticClass:"overflow-hidden relative",style:({ paddingTop: `${_vm.roomRatio * 100}%` })},[_c('div',{staticClass:"absolute inset-0 w-full h-full bg-gray-100 border border-gray-300 shadow-inner"},[_c('div',{staticClass:"absolute bg-white ring-1 ring-inset ring-gray-300",style:({
          top: `${(_vm.gapRight / _vm.roomWidth) * 100}%`,
          left: `${(_vm.gapFront / _vm.roomLength) * 100}%`,
          right: `${(_vm.gapBack / _vm.roomLength) * 100}%`,
          bottom: `${(_vm.gapLeft / _vm.roomWidth) * 100}%`,
        })},[_c('div',{staticClass:"absolute inset-0 flex items-stretch justify-end w-full h-full"},_vm._l((_vm.podestenReversed),function(podest,index){return _c('div',{key:index,staticClass:"absolute flex items-start justify-start h-full pl-3",style:({
              width: `${(podest.width / _vm.panelSpace('horizontal')) * 100}%`,
            })},[_c('div',{staticClass:"absolute inset-0 w-full h-full border-l-2"}),_c('div',{staticClass:"flex flex-col items-start text-xs font-semibold text-gray-500 whitespace-nowrap mt-16"},[_c('div',[_vm._v(_vm._s(podest.name))]),_c('div',[_vm._v(_vm._s(podest.width)+" cm")])])])}),0),_c('InterieurObjecten'),(
            _vm.curveDiffusersVoorwand !== -1 &&
              _vm.voorwandCurveDiffuserVlakkeAfwerking === false
          )?_c('div',{staticClass:"absolute top-0 left-0 bg-purple-400 pointer-events-none rounded-br-full",style:({
            width: `${(_vm.curveDiffusersVoorwand / _vm.panelSpace('horizontal')) *
              100}%`,
            height: `${(_vm.curveDiffusersVoorwand / _vm.panelSpace('vertical')) *
              100}%`,
          })}):_vm._e(),(
            _vm.curveDiffusersVoorwand !== -1 &&
              _vm.voorwandCurveDiffuserVlakkeAfwerking
          )?_c('div',{staticClass:"absolute top-0 left-0 pointer-events-none",style:({
            width: `${(_vm.curveDiffusersVoorwand / _vm.panelSpace('horizontal')) *
              100}%`,
            height: `${(_vm.curveDiffusersVoorwand / _vm.panelSpace('vertical')) *
              100}%`,
          })},[_c('img',{attrs:{"src":"/img/triangle-green.png"}})]):_vm._e(),(
            _vm.curveDiffusersVoorwand !== -1 &&
              _vm.voorwandCurveDiffuserVlakkeAfwerking === false
          )?_c('div',{staticClass:"absolute bottom-0 left-0 bg-purple-400 pointer-events-none rounded-tr-full",style:({
            width: `${(_vm.curveDiffusersVoorwand / _vm.panelSpace('horizontal')) *
              100}%`,
            height: `${(_vm.curveDiffusersVoorwand / _vm.panelSpace('vertical')) *
              100}%`,
          })}):_vm._e(),(
            _vm.curveDiffusersVoorwand !== -1 &&
              _vm.voorwandCurveDiffuserVlakkeAfwerking
          )?_c('div',{staticClass:"absolute bottom-0 left-0 pointer-events-none -rotate-90",style:({
            width: `${(_vm.curveDiffusersVoorwand / _vm.panelSpace('horizontal')) *
              100}%`,
            height: `${(_vm.curveDiffusersVoorwand / _vm.panelSpace('vertical')) *
              100}%`,
          })},[_c('img',{attrs:{"src":"/img/triangle-green.png"}})]):_vm._e(),(
            _vm.curveDiffusersAchterwand !== -1 &&
              _vm.achterwandCurveDiffuserVlakkeAfwerking === false
          )?_c('div',{staticClass:"absolute top-0 right-0 bg-purple-400 pointer-events-none rounded-bl-full",style:({
            width: `${(_vm.curveDiffusersAchterwand / _vm.panelSpace('horizontal')) *
              100}%`,
            height: `${(_vm.curveDiffusersAchterwand / _vm.panelSpace('vertical')) *
              100}%`,
          })}):_vm._e(),(
            _vm.curveDiffusersAchterwand !== -1 &&
              _vm.achterwandCurveDiffuserVlakkeAfwerking
          )?_c('div',{staticClass:"absolute top-0 right-0 pointer-events-none rotate-90",style:({
            width: `${(_vm.curveDiffusersAchterwand / _vm.panelSpace('horizontal')) *
              100}%`,
            height: `${(_vm.curveDiffusersAchterwand / _vm.panelSpace('vertical')) *
              100}%`,
          })},[_c('img',{attrs:{"src":"/img/triangle-green.png"}})]):_vm._e(),(
            _vm.curveDiffusersAchterwand !== -1 &&
              _vm.achterwandCurveDiffuserVlakkeAfwerking === false
          )?_c('div',{staticClass:"absolute bottom-0 right-0 bg-purple-400 pointer-events-none rounded-tl-full",style:({
            width: `${(_vm.curveDiffusersAchterwand / _vm.panelSpace('horizontal')) *
              100}%`,
            height: `${(_vm.curveDiffusersAchterwand / _vm.panelSpace('vertical')) *
              100}%`,
          })}):_vm._e(),(
            _vm.curveDiffusersAchterwand !== -1 &&
              _vm.achterwandCurveDiffuserVlakkeAfwerking
          )?_c('div',{staticClass:"absolute bottom-0 right-0 pointer-events-none rotate-180",style:({
            width: `${(_vm.curveDiffusersAchterwand / _vm.panelSpace('horizontal')) *
              100}%`,
            height: `${(_vm.curveDiffusersAchterwand / _vm.panelSpace('vertical')) *
              100}%`,
          })},[_c('img',{attrs:{"src":"/img/triangle-green.png"}})]):_vm._e(),_c('wall-panels'),_c('surround-sound-indicators'),(_vm.sweetspotShown)?_c('div',{staticClass:"absolute top-1/2 -translate-y-1/2 translate-x-1/2 text-center flex items-center justify-center text-xs font-semibold text-gray-500 pointer-events-none",style:({
            width: `${(300 / _vm.panelSpace('horizontal')) * 100}%`,
            height: `${(300 / _vm.panelSpace('vertical')) * 100}%`,
            right: `${_vm.sweetspotPosition}%`,
          })},[_c('div',[_c('div',[_vm._v(" Sweetspot ")]),_c('div',[_vm._v(" "+_vm._s(Math.round( (_vm.panelSpace("horizontal") * _vm.sweetspotPosition) / 100 ))+" cm ")])])]):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.ceilingShown && _vm.ceilingType === 'Latten'),expression:"ceilingShown && ceilingType === 'Latten'"}],staticClass:"absolute inset-0 flex items-center bg-gray-500 bg-opacity-60 z-20"},[_c('div',{staticClass:"absolute",style:({
            width: `${(_vm.ceilingLength / _vm.roomLength) * 100}%`,
            height: `${(_vm.ceilingWidth / _vm.roomWidth) * 100}%`,
            left: `${(_vm.ceilingDistanceToFront / _vm.roomLength) * 100}%`,
          })},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.ceilingType === 'Latten'),expression:"ceilingType === 'Latten'"}],staticClass:"flex items-stretch justify-between h-full"},_vm._l((_vm.latten),function(lat,index){return _c('div',{key:index,staticClass:"bg-white rounded-sm drop-shadow",style:({ width: `${(lat / _vm.ceilingLength) * 100}%` })})}),0)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.ceilingShown && _vm.ceilingType === 'Cassette'),expression:"ceilingShown && ceilingType === 'Cassette'"}],staticClass:"absolute inset-0 grid content-center bg-gray-500 bg-opacity-60 z-20",style:({
          'grid-template-rows': `repeat(${_vm.cassetteRows}, ${(_vm.cassetteWidth /
            _vm.roomWidth) *
            100}%)`,
          'grid-template-columns': `${(_vm.ceilingDistanceToFront / _vm.roomLength) *
            100}% repeat(${_vm.cassetteColumns}, ${(_vm.cassetteLength / _vm.roomLength) *
            100}%)`,
          'grid-row-gap': `${(_vm.cassetteGapY / _vm.roomWidth) * 100}%`,
          'grid-column-gap': `${(_vm.cassetteGapX / _vm.roomLength) * 100}%`,
        })},_vm._l((_vm.cassetteRows * _vm.cassetteColumns),function(i){return _c('div',{key:i,staticClass:"w-full h-full bg-white border rounded-sm drop-shadow",style:({
            'grid-column-start':
              (i - 1) % _vm.cassetteColumns === 0 ? 2 : 'initial',
          })})}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.ceilingShown && _vm.ceilingType === 'Absorptiepanelen'),expression:"ceilingShown && ceilingType === 'Absorptiepanelen'"}],staticClass:"absolute inset-0 grid content-center bg-gray-500 bg-opacity-60",style:({
          'grid-template-rows': `repeat(${_vm.absorptionPanelsRows}, ${(_vm.absorptionPanelsWidth /
            _vm.roomWidth) *
            100}%)`,
          'grid-template-columns': `${(_vm.ceilingDistanceToFront / _vm.roomLength) *
            100}% repeat(${_vm.absorptionPanelsColumns}, ${(_vm.absorptionPanelsLength /
            _vm.roomLength) *
            100}%)`,
          'grid-row-gap': `${(_vm.absorptionPanelsDistanceBetween / _vm.roomWidth) *
            100}%`,
          'grid-column-gap': `${(_vm.absorptionPanelsDistanceBetween /
            _vm.roomLength) *
            100}%`,
        })},_vm._l((_vm.absorptionPanelsRows * _vm.absorptionPanelsColumns),function(i){return _c('div',{key:i,staticClass:"w-full h-full bg-white border rounded-sm drop-shadow",style:({
            'grid-column-start':
              (i - 1) % _vm.absorptionPanelsColumns === 0 ? 2 : 'initial',
          })})}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }