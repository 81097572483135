var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
    show: (_vm.labelShown || _vm.allLabelsShown) && _vm.objectData.type !== 'Schaduwlat',
    content: `${_vm.objectData.isCornerElement ? 'H' : _vm.elementId}`,
    trigger: 'manual',
    placement: _vm.orientation === 'vertical' ? 'right' : 'top',
  }),expression:"{\n    show: (labelShown || allLabelsShown) && objectData.type !== 'Schaduwlat',\n    content: `${objectData.isCornerElement ? 'H' : elementId}`,\n    trigger: 'manual',\n    placement: orientation === 'vertical' ? 'right' : 'top',\n  }"}],staticClass:"relative transition cursor-pointer ring-1 ring-inset before:absolute before:content pointer-events-auto",class:{
    'before:-right-1 before:-left-1 before:top-0 before:bottom-0':
      _vm.wallIndex === 0 || _vm.wallIndex === 3,
    'before:-top-1 before:-bottom-1 before:right-0 before:left-0':
      _vm.wallIndex === 1 || _vm.wallIndex === 2,
    'bg-teal-500 hover:bg-teal-600 ring-teal-400': _vm.objectData.color
      ? _vm.objectData.color === 'teal'
      : _vm.objectData.type === 'Absorber',
    'bg-purple-500 hover:bg-purple-600 ring-purple-400': _vm.objectData.color
      ? _vm.objectData.color === 'purple'
      : _vm.objectData.type === 'CurveDiffuser',
    'bg-blue-500 hover:bg-blue-600 ring-blue-400': _vm.objectData.color
      ? _vm.objectData.color === 'blue'
      : _vm.objectData.type === 'Flatdiffuser',
    'bg-lime-500 hover:bg-lime-600 ring-lime-400': _vm.objectData.color
      ? _vm.objectData.color === 'lime'
      : _vm.objectData.type === 'v10 panel',
    'bg-red-500 hover:bg-red-600 ring-red-400': _vm.objectData.color
      ? _vm.objectData.color === 'red'
      : _vm.objectData.type === 'Reflector',
    'bg-fuchsia-500 hover:bg-fuchsia-600 ring-fuchsia-400': _vm.objectData.color
      ? _vm.objectData.color === 'fuchsia'
      : _vm.objectData.type === 'Visual',
    'bg-gray-500 hover:bg-gray-600 ring-gray-400': _vm.objectData.color
      ? _vm.objectData.color === 'gray'
      : _vm.objectData.type === 'Leeg' ||
        _vm.objectData.type === 'Schaduwlat' ||
        _vm.objectData.type === 'Schaduwblok',
    'bg-yellow-500 hover:bg-yellow-600 ring-yellow-400': _vm.objectData.color
      ? _vm.objectData.color === 'yellow'
      : _vm.objectData.type === 'Anders',
  },style:(_vm.styleObject),on:{"mouseenter":function($event){return _vm.hoverObject(true)},"mouseleave":function($event){return _vm.hoverObject(false)},"click":function($event){return _vm.editObject(_vm.wallIndex, _vm.objectIndex)}}},[(
      _vm.objectData.otherType
        ? _vm.objectData.otherType.toLowerCase().includes('deur') ||
          _vm.objectData.otherType.toLowerCase().includes('raam') ||
          _vm.objectData.otherType.toLowerCase().includes('doorgang')
        : false || _vm.objectData.isInfrontOfOpening
    )?_c('div',{staticClass:"absolute z-10 bg-white",class:{
      'right-full w-80 h-full': _vm.wallIndex === 0,
      'left-full w-80 h-full': _vm.wallIndex === 3,
      'top-full h-80 w-full': _vm.wallIndex === 2,
      'bottom-full h-80 w-full': _vm.wallIndex === 1,
    }}):_vm._e(),(_vm.objectData.hasSpeaker)?_c('div',{staticClass:"absolute z-[0] overflow-hidden text-gray-700",class:{
      'top-1/2 -translate-y-1/2': _vm.orientation === 'vertical',
      'left-1/2 -translate-x-1/2': _vm.orientation === 'horizontal',
      'right-0 mr-[-11px]': _vm.wallIndex === 0,
      'rotate-90 bottom-0 mb-[-11px]': _vm.wallIndex === 1,
      '-rotate-90 top-0 mt-[-11px]': _vm.wallIndex === 2,
      'rotate-180 left-0 ml-[-11px]': _vm.wallIndex === 3,
    }},[_c('svg',{staticClass:"w-6 h-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","fill":"currentColor"}},[_c('path',{attrs:{"d":"M13.5 4.06C13.5 2.724 11.884 2.055 10.94 3L6.44 7.5H4.508C3.367 7.5 2.19 8.164 1.848 9.405C1.61598 10.2504 1.49892 11.1233 1.5 12C1.5 12.898 1.621 13.768 1.85 14.595C2.191 15.835 3.368 16.5 4.509 16.5H6.439L10.939 21C11.884 21.945 13.5 21.276 13.5 19.94V4.06Z","fill":"currentColor","stroke":"white","stroke-width":"2"}})])]):_vm._e(),(_vm.objectData.type === 'CurveDiffuser' && !_vm.objectData.isCornerElement)?_c('div',{staticClass:"absolute z-[0] overflow-hidden",class:{
      'left-full h-full': _vm.wallIndex === 0,
      'right-full h-full': _vm.wallIndex === 3,
      'bottom-full w-full': _vm.wallIndex === 2,
      'top-full w-full': _vm.wallIndex === 1,
    }},[_c('div',{staticClass:"bg-purple-400",class:{
        '-translate-x-1/2 h-full aspect-2-5 right-curve': _vm.wallIndex === 0,
        'translate-x-1/2 h-full aspect-2-5 left-curve': _vm.wallIndex === 3,
        'translate-y-1/2 w-full aspect-5-2 top-curve': _vm.wallIndex === 2,
        '-translate-y-1/2 w-full aspect-5-2 bottom-curve': _vm.wallIndex === 1,
      }})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }