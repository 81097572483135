<template>
  <div>
    <div class="flex items-center justify-between pt-8 pb-2 px-4">
      <div class="flex items-center">
        <img class="w-32" src="/img/esperio-logo.avif" alt="Esperio Logo" />
        <div class="ml-2 text-lg font-semibold text-gray-800">Planner</div>
      </div>
    </div>
    <div class="max-w-7xl">
      <summary-data :data="projectData" />
      <summary-data :data="roomData" />
      <summary-data :data="absorbtionReflectionAndDiffusionData" />
      <summary-data :data="ceilingData" />
    </div>
    <div class="p-4 mt-8 max-w-7xl text-black">
      <table class="w-full">
        <thead>
          <tr class="text-sm font-semibold text-gray-600">
            <td>Code</td>
            <td>Object</td>
            <td>Hoogte</td>
            <td>Breedte</td>
            <td>Dikte</td>
          </tr>
        </thead>
        <tbody
          class="text-sm font-semibold text-gray-800"
          v-for="(wall, wallIndex) in walls"
          :key="wallIndex"
        >
          <tr v-if="curveDiffusersVoorwand !== -1 && wallIndex === 0">
            <td>HV</td>
            <td>
              {{
                voorwandCurveDiffuserVlakkeAfwerking
                  ? "Hoekelement"
                  : curveDiffusersVoorwand === 5.5
                  ? "Schaduwblok"
                  : "CurveDiffuser"
              }}
            </td>
            <td>{{ panelHeight }}</td>
            <td>{{ Math.round(curveDiffusersVoorwand / 0.7 / 10) * 10 }}</td>
          </tr>
          <tr v-if="curveDiffusersAchterwand !== -1 && wallIndex === 3">
            <td>HA</td>
            <td>
              {{
                achterwandCurveDiffuserVlakkeAfwerking
                  ? "Hoekelement"
                  : curveDiffusersAchterwand === 5.5
                  ? "Schaduwblok"
                  : "CurveDiffuser"
              }}
            </td>
            <td>{{ panelHeight }}</td>
            <td>{{ Math.round(curveDiffusersAchterwand / 0.7 / 10) * 10 }}</td>
          </tr>
          <tr
            v-for="(object, objectIndex) in wall.objects"
            :key="objectIndex"
            v-show="
              object.type !== 'Schaduwlat' &&
                object.otherType.toLowerCase().includes('schaduw') === false
            "
          >
            <td>{{ getElementId(wallIndex, objectIndex) }}</td>
            <td>
              {{ object.type === "Anders" ? object.otherType : object.type }}
            </td>
            <td>{{ object.height }}</td>
            <td>
              <span v-if="object.size !== 'auto'">
                {{
                  object.size === "custom"
                    ? object.otherSize.toString().replace(".", ",")
                    : object.size.toString().replace(".", ",")
                }}
              </span>
              <span v-else>
                {{
                  autoSizedObjectsSize(wallIndex)
                    .toString()
                    .replace(".", ",")
                }}
              </span>
            </td>
            <td>{{ object.thickness.toString().replace(".", ",") }}</td>
          </tr>
          <tr v-if="curveDiffusersVoorwand !== -1 && wallIndex === 0">
            <td>HV</td>
            <td>
              {{
                voorwandCurveDiffuserVlakkeAfwerking
                  ? "Hoekelement"
                  : curveDiffusersVoorwand === 5.5
                  ? "Schaduwblok"
                  : "CurveDiffuser"
              }}
            </td>
            <td>{{ panelHeight }}</td>
            <td>{{ Math.round(curveDiffusersVoorwand / 0.7 / 10) * 10 }}</td>
          </tr>
          <tr v-if="curveDiffusersAchterwand !== -1 && wallIndex === 3">
            <td>HA</td>
            <td>
              {{
                achterwandCurveDiffuserVlakkeAfwerking
                  ? "Hoekelement"
                  : curveDiffusersAchterwand === 5.5
                  ? "Schaduwblok"
                  : "CurveDiffuser"
              }}
            </td>
            <td>{{ panelHeight }}</td>
            <td>{{ Math.round(curveDiffusersAchterwand / 0.7 / 10) * 10 }}</td>
          </tr>
          <tr>
            <td class="py-2"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import SummaryData from "./SummaryData";

export default {
  name: "RoomSummary",
  components: {
    SummaryData,
  },
  computed: {
    ...mapGetters([
      "walls",
      "projectName",
      "clientName",
      "salesOwner",
      "projectDate",
      "roomLength",
      "roomWidth",
      "roomHeight",
      "roomRatio",
      "gapFront",
      "gapRight",
      "gapLeft",
      "gapBack",
      "panelWidth",
      "panelHeight",
      "insideWidth",
      "insideLength",
      "ceilingType",
      "ceilingWidth",
      "ceilingLength",
      "koof",
      "ceilingDistanceToFront",
      "absorptionPanelsSize",
      "absorptionPanelsRows",
      "absorptionPanelsColumns",
      "absorptionPanelsOrientation",
      "absorptionPanelsDistanceBetween",
      "cassetteWidth",
      "cassetteLength",
      "cassetteRows",
      "cassetteColumns",
      "totalAbsorbtion",
      "totalReflection",
      "totalDiffusion",
      "autoSizedObjectsSize",
      "curveDiffusersVoorwand",
      "curveDiffusersAchterwand",
      "version",
      "voorwandCurveDiffuserVlakkeAfwerking",
      "achterwandCurveDiffuserVlakkeAfwerking",
    ]),
    roomArea() {
      return Math.round((this.roomLength * this.roomWidth) / 10000);
    },
    projectData() {
      return [
        {
          label: "Projectnaam",
          value: this.projectName,
        },
        {
          label: "Klantnaam",
          value: this.clientName,
        },
        {
          label: "Datum",
          value: this.projectDate,
        },
        {
          label: "Versie",
          value: this.version,
        },
      ];
    },
    roomData() {
      return [
        {
          label: "Totale lengte x breedte x hoogte",
          value:
            this.roomLength +
            " x " +
            this.roomWidth +
            " x " +
            this.roomHeight +
            " cm",
        },
        {
          label: "Oppervlakte / inhoud",
          value:
            Math.round(((this.roomLength * this.roomWidth) / 10000) * 10) / 10 +
            " m² / " +
            Math.round(
              ((this.roomLength * this.roomWidth * this.roomHeight) / 1000000) *
                10
            ) /
              10 +
            " m³",
        },
        {
          label: "Verhouding ruimte (l : b)",
          value: "1 : " + Math.round(this.roomRatio * 100) / 100,
        },
        {
          label: "Absorptie achter panelen (V, R, L, A)",
          value:
            this.gapFront +
            ", " +
            this.gapRight +
            ", " +
            this.gapLeft +
            ", " +
            this.gapBack +
            " cm",
        },
      ];
    },
    ceilingData() {
      return [
        {
          label: "Plafondinvulling",
          value: this.ceilingType,
        },
        {
          label: "Koof",
          value: this.koof,
          hide: this.ceilingType === "Absorptiepanelen",
        },
        {
          label: "Afmetingen",
          value: `${this.ceilingWidth} x ${this.ceilingLength} cm`,
          hide: this.ceilingType !== "Latten",
        },
        {
          label: "Cassettegrootte",
          value: `${this.cassetteWidth} x ${this.cassetteLength} cm`,
          hide: this.ceilingType !== "Cassette",
        },
        {
          label: "Aantal cassettes",
          value: this.cassetteRows * this.cassetteColumns,
          hide: this.ceilingType !== "Cassette",
        },
        {
          label: "Afstand tot voorwand",
          value: this.ceilingDistanceToFront + " cm",
        },
        {
          label: "Latten opbouw",
          value: this.generateLatten(),
          hide: this.ceilingType !== "Latten",
        },
        {
          label:
            "Ruimte tussen elke lat en tussen de eerste en laatste lat en de koof",
          value: this.calculateLattenTussenruimte(),
          hide: this.ceilingType !== "Latten",
        },
        {
          label: "Absorptiepaneelgrootte",
          value: this.absorptionPanelsSize + " cm",
          hide: this.ceilingType !== "Absorptiepanelen",
        },
        {
          label: "Aantal absorptiepanelen",
          value: this.absorptionPanelsRows * this.absorptionPanelsColumns,
          hide: this.ceilingType !== "Absorptiepanelen",
        },
        {
          label: "Absorptiepaneel oriëntatie",
          value: this.absorptionPanelsOrientation,
          hide: this.ceilingType !== "Absorptiepanelen",
        },
        {
          label: "Ruimte tussen de panelen",
          value: this.absorptionPanelsDistanceBetween + " cm",
          hide: this.ceilingType !== "Absorptiepanelen",
        },
      ];
    },
    absorbtionReflectionAndDiffusionData() {
      return [
        {
          label: "Absorptie achter de wanden",
          value:
            Math.round(
              ((this.gapFront * this.roomHeight * this.roomWidth) / 1000000 +
                (this.gapLeft * this.roomHeight * this.roomLength) / 1000000 +
                (this.gapRight * this.roomHeight * this.roomLength) / 1000000 +
                (this.gapBack * this.roomHeight * this.roomWidth) / 1000000) *
                100
            ) /
              100 +
            " m³",
        },
        {
          label: "Totale absorptie wandpanelen",
          value:
            Math.round(this.totalAbsorbtion * 100) / 100 +
            " m² (" +
            Math.round((this.totalAbsorbtion / this.roomArea) * 100) +
            "%)",
        },
        {
          label: "Totale diffusie wandpanelen",
          value:
            Math.round(this.totalDiffusion * 100) / 100 +
            " m² (" +
            Math.round((this.totalDiffusion / this.roomArea) * 100) +
            "%)",
        },
        {
          label: "Totale reflectie wandpanelen",
          value:
            Math.round(this.totalReflection * 100) / 100 +
            " m² (" +
            Math.round((this.totalReflection / this.roomArea) * 100) +
            "%)",
        },
      ];
    },
  },
  methods: {
    getElementId(wallIndex, objectIndex) {
      if (wallIndex === 0) {
        return `V${objectIndex + 1}`;
      } else if (wallIndex === 1) {
        return `R${objectIndex + 1}`;
      } else if (wallIndex === 2) {
        return `L${objectIndex + 1}`;
      } else {
        return `A${objectIndex + 1}`;
      }
    },
    generateLatten() {
      const lattenArray = this.$store.getters.lattenList;
      return [
        `${lattenArray.filter(lat => lat === 5).length} stuks van 5 x ${
          this.ceilingWidth
        } cm`,
        `${lattenArray.filter(lat => lat === 9).length} stuks van 9 x ${
          this.ceilingWidth
        } cm`,
        `${lattenArray.filter(lat => lat === 12).length} stuks van 12 x ${
          this.ceilingWidth
        } cm`,
      ];
    },
    calculateLattenTussenruimte() {
      const lattenArray = this.$store.getters.lattenList;
      let totalLattenLength = 0;
      let lattenAmount = lattenArray.length;
      lattenArray.forEach(lat => {
        totalLattenLength += lat;
      });
      const emptySpace = this.ceilingLength - totalLattenLength;
      return Math.round((emptySpace / (lattenAmount + 1)) * 100) / 100 + " cm";
    },
  },
};
</script>
