var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"font-semibold text-gray-300",class:{
      'text-xs': _vm.size === 'small',
      'text-sm': _vm.size !== 'small',
    }},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('div',{staticClass:"relative mt-1"},[_c('select',{staticClass:"w-full font-semibold leading-6 text-white transition bg-gray-700 border border-gray-600 rounded appearance-none cursor-pointer focus:border-primary-600 ring-primary-600 focus:ring-1 focus:outline-none tabular-nums",class:{
        'py-0.5 pl-2 pr-4 text-xs': _vm.size === 'small',
        'py-1 pl-3 pr-6 text-sm': _vm.size !== 'small',
      },domProps:{"value":_vm.value},on:{"input":function($event){_vm.type === 'number'
          ? _vm.$emit('input', Number($event.target.value))
          : _vm.$emit('input', $event.target.value)}}},_vm._l((_vm.options),function(option){return _c('option',{key:option.value,staticClass:"font-semibold",domProps:{"value":option.value}},[_vm._v(_vm._s(option.label))])}),0),_c('div',{staticClass:"absolute top-0 flex items-center h-full pointer-events-none right-1"},[_c('chevron-down-icon',{staticClass:"w-5 h-5 text-gray-500"})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }