<template>
  <div
    id="app"
    class="relative grid h-screen font-medium text-white bg-gray-800 selection:bg-primary-600 selection:text-white"
    style="grid-template-rows: auto 1fr"
  >
    <app-header
      @print="generatePdf"
      @setBase64PlanToExport="setBase64PlanToExport"
    />
    <div class="flex items-stretch min-h-full">
      <app-sidebar />
      <div class="flex-1 px-6 pt-6 pb-6 overflow-y-auto bg-white">
        <vue-html2pdf
          :show-layout="true"
          :float-layout="false"
          :enable-download="false"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="testname"
          :pdf-quality="2"
          :manual-pagination="true"
          pdf-format="a4"
          :pdf-orientation="pdfOrientation"
          :pdf-content-width="`${pdfContentWidth}%`"
          ref="html2Pdf"
          @progress="handleProgress"
        >
          <section slot="pdf-content" class="relative">
            <app-floor-plan />
            <div
              class="absolute bg-gray-100 text-gray-800 rounded px-4 py-3 border border-gray-300 max-w-[16rem] select-none"
              :class="{
                'cursor-[grabbing]': isDraggingProjectDescription,
                'cursor-[grab]': !isDraggingProjectDescription,
              }"
              :style="{
                left: `${projectDescriptionX || 0}px`,
                top: `${projectDescriptionY || 0}px`,
              }"
              @mousedown="isDraggingProjectDescription = true"
              v-if="projectDescriptionShown"
            >
              <div class="font-bold text-sm">
                <div>{{ projectName }}</div>
              </div>
              <div class="mt-2">
                <div class="text-xs font-semibold text-gray-700">
                  Ontwerp versie {{ version }}
                </div>
                <div class="text-xs font-semibold text-gray-700">
                  {{ new Date().toLocaleDateString() }}
                </div>
              </div>
              <div class="font-semibold text-xs mt-2">
                {{ projectDescription }}
              </div>
              <div class="mt-3 font-semibold text-xs">
                <div class="flex items-end justify-between mt-3">
                  <img
                    class="w-14"
                    src="/img/esperio-logo.avif"
                    alt="Esperio Logo"
                  />
                  <a href="https://esperio.nl" class="text-blue-500"
                    >esperio.nl</a
                  >
                </div>
              </div>
            </div>
            <div
              class="absolute bottom-20 left-1/2 -translate-x-1/2 flex flex-wrap gap-x-3 gap-y-1 p-2 bg-gray-100 border border-gray-300 rounded"
              v-if="showProjectDetails"
            >
              <div
                class="flex items-center space-x-1"
                v-for="color in [
                  {
                    name: 'Absorber',
                    classes: 'bg-teal-500 border-teal-400',
                  },
                  {
                    name: 'CurveDiffuser',
                    classes: 'bg-purple-500 border-purple-400',
                  },
                  {
                    name: 'FlatDiffuser',
                    classes: 'bg-blue-500 border-blue-400',
                  },
                  {
                    name: 'v10 panel',
                    classes: 'bg-lime-500 border-lime-400',
                  },
                  {
                    name: 'Reflector',
                    classes: 'bg-red-500 border-red-400',
                  },
                  {
                    name: 'Visual',
                    classes: 'bg-fuchsia-500 border-fuchsia-400',
                  },
                  {
                    name: 'Leeg/Schaduwlat/Schaduwblok',
                    classes: 'bg-gray-500 border-gray-400',
                  },
                  {
                    name: 'Anders',
                    classes: 'bg-yellow-500 border-yellow-400',
                  },
                ]"
                :key="color.name"
              >
                <div
                  class="w-3 h-3 border rounded-sm"
                  :class="color.classes"
                ></div>
                <div class="text-gray-500 text-xs font-semibold">
                  {{ color.name }}
                </div>
              </div>
            </div>
          </section>
        </vue-html2pdf>
        <div class="flex flex-wrap mt-2 gap-x-3 gap-y-1 py-2">
          <div
            class="flex items-center space-x-1"
            v-for="color in [
              {
                name: 'Absorber',
                classes: 'bg-teal-500 border-teal-400',
              },
              {
                name: 'CurveDiffuser',
                classes: 'bg-purple-500 border-purple-400',
              },
              {
                name: 'FlatDiffuser',
                classes: 'bg-blue-500 border-blue-400',
              },
              {
                name: 'v10 panel',
                classes: 'bg-lime-500 border-lime-400',
              },
              {
                name: 'Reflector',
                classes: 'bg-red-500 border-red-400',
              },
              {
                name: 'Visual',
                classes: 'bg-fuchsia-500 border-fuchsia-400',
              },
              {
                name: 'Leeg/Schaduwlat/Schaduwblok',
                classes: 'bg-gray-500 border-gray-400',
              },
              {
                name: 'Anders',
                classes: 'bg-yellow-500 border-yellow-400',
              },
            ]"
            :key="color.name"
          >
            <div class="w-3 h-3 border rounded-sm" :class="color.classes"></div>
            <div class="text-gray-500 text-xs font-semibold">
              {{ color.name }}
            </div>
          </div>
        </div>
        <room-summary />
      </div>
    </div>
    <transition name="fade">
      <create-panel-modal
        v-if="showCreatePanelModal"
        @close="showCreatePanelModal = false"
        :shown="showCreatePanelModal"
      />
    </transition>
    <transition name="fade">
      <edit-panel-modal
        v-if="showEditPanelModal"
        @close="showEditPanelModal = false"
        :objectToEdit="objectToEdit"
        :shown="showEditPanelModal"
      />
    </transition>
    <transition name="fade">
      <div
        class="fixed inset-0 z-20 flex items-center justify-center w-full h-full bg-black cursor-pointer bg-opacity-80"
        v-show="importPromptShown"
        @click.self="importPromptShown = false"
      >
        <div
          class="px-5 py-4 bg-gray-800 border border-gray-700 rounded shadow-lg cursor-auto"
        >
          <div class="flex items-center justify-between">
            <div class="font-bold">Importeer ontwerp</div>
            <button
              class="p-2 transition rounded focus:outline-none hover:bg-gray-700"
              @click="importPromptShown = false"
            >
              <times-icon class="w-5 h-5" />
            </button>
          </div>
          <div class="mt-4 flex items-end">
            <input-group
              class="w-64"
              name="base64PlanToImport"
              label="Plak hier de ontwerpcode"
              v-model="base64PlanToImport"
            />
            <div class="px-3 pb-2 text-sm font-semibold text-gray-300">
              of
            </div>
            <label
              class="flex items-center text-sm font-semibold leading-4 text-white bg-primary-600 border-b-2 border-primary-800 rounded px-3 py-2 cursor-pointer"
              for="eplFileUpload"
            >
              <UploadIcon />
              <div class="ml-2">
                Upload .epl bestand
              </div>
              <input
                class="sr-only"
                id="eplFileUpload"
                type="file"
                @input="handleEplFileImport"
                accept=".epl"
              />
            </label>
          </div>
          <base-button class="mt-4" @click="importBase64Plan"
            >Importeer</base-button
          >
          <div class="mt-4 text-sm font-semibold text-red-500 w-96">
            Het importeren van een plan is onomkeerbeer. Het huidige plan wordt
            dan ook volledig overschreven.
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div
        class="fixed inset-0 z-20 flex items-center justify-center w-full h-full bg-black cursor-pointer bg-opacity-80"
        v-show="exportPromptShown"
        @click.self="exportPromptShown = false"
      >
        <div
          class="px-5 py-4 bg-gray-800 border border-gray-700 rounded shadow-lg cursor-auto"
        >
          <div class="flex items-center justify-between">
            <div class="font-bold">Exporteer ontwerp</div>
            <button
              class="p-2 transition rounded focus:outline-none hover:bg-gray-700"
              @click="exportPromptShown = false"
            >
              <times-icon class="w-5 h-5" />
            </button>
          </div>
          <input-group
            class="mt-4 w-96"
            name="base64PlanToExport"
            label="Ontwerpcode"
            v-model="base64PlanToExport"
            :readonly="true"
          />
          <div class="flex items-center mt-4 space-x-3">
            <base-button
              class="relative"
              @click="copyBase64PlanToExport"
              icon="CopyIcon"
            >
              Kopieer naar klembord
              <transition name="fade">
                <div
                  class="absolute left-1/2 -translate-x-1/2 bottom-full bg-gray-900 text-sm font-semibold text-gray-300 px-3 py-2 rounded mb-1.5 transition pointer-events-none"
                  v-show="base64PlanCopied"
                >
                  Gekopiëerd!
                </div>
              </transition>
            </base-button>
            <base-button @click="downloadPlan" icon="DownloadIcon">
              Download .epl bestand
            </base-button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";

import VueHtml2pdf from "vue-html2pdf";

import AppHeader from "./components/layout/AppHeader";
import AppSidebar from "./components/layout/AppSidebar";
import AppFloorPlan from "./components/layout/AppFloorPlan";
import RoomSummary from "./components/RoomSummary";

import CreatePanelModal from "./components/CreatePanelModal";
import EditPanelModal from "./components/EditPanelModal";
import InputGroup from "./components/elements/InputGroup";
import BaseButton from "./components/elements/BaseButton";

import TimesIcon from "./components/icons/TimesIcon";
import UploadIcon from "./components/icons/UploadIcon";

import { decode } from "js-base64";

let mousePosX = 0;
let mousePosY = 0;

export default {
  name: "App",
  data() {
    return {
      base64PlanCopied: false,
      base64PlanToImport: "",
      base64PlanToExport: "",
      pdfContentWidth: 100,
      showProjectDetails: false,
    };
  },
  components: {
    VueHtml2pdf,
    AppHeader,
    AppSidebar,
    AppFloorPlan,
    RoomSummary,
    InputGroup,
    CreatePanelModal,
    EditPanelModal,
    BaseButton,
    TimesIcon,
    UploadIcon,
  },
  computed: {
    projectName: get("projectName"),
    clientName: get("clientName"),
    version: get("version"),
    projectDescription: get("projectDescription"),
    roomLength: get("roomLength"),
    roomWidth: get("roomWidth"),
    roomRatio: get("roomRatio"),
    gapFront: get("gapFront"),
    gapSides: get("gapSides"),
    gapBack: get("gapBack"),
    panelWidth: get("panelWidth"),
    panelSpace: get("panelSpace"),
    showCreatePanelModal: sync("showCreatePanelModal"),
    showEditPanelModal: sync("showEditPanelModal"),
    objectToEdit: sync("objectToEdit"),
    walls: get("walls"),
    totalObjectSize: get("totalObjectSize"),
    importPromptShown: sync("importPromptShown"),
    exportPromptShown: sync("exportPromptShown"),
    projectDescriptionShown: sync("projectDescriptionShown"),
    projectDescriptionX: sync("projectDescriptionX"),
    projectDescriptionY: sync("projectDescriptionY"),
    isDraggingProjectDescription: sync("isDraggingProjectDescription"),
    pdfOrientation() {
      if (this.roomWidth > this.roomLength) {
        return "portrait";
      }
      return "landscape";
    },
  },
  methods: {
    editObject(wallIndex, objectIndex) {
      this.objectToEdit = {
        objectIndex,
        wallIndex,
        ...this.walls[wallIndex].objects[objectIndex],
      };
      this.showEditPanelModal = true;
    },
    duplicateObject(wallIndex, objectIndex) {
      this.$store.commit("duplicateObject", { wallIndex, objectIndex });
    },
    duplicateWall(direction) {
      this.$store.commit("duplicateWall", direction);
    },
    generatePdf() {
      let pdfWidthRatio;
      const a4Ratio = 1.414285714;
      if (this.roomLength / this.roomWidth < a4Ratio) {
        const roomRatio = this.roomLength / this.roomWidth;
        const diff = (roomRatio - a4Ratio) / a4Ratio;
        pdfWidthRatio = 100 + diff * 100;
        console.log(pdfWidthRatio, diff, roomRatio, a4Ratio);
      } else {
        pdfWidthRatio = 100;
      }
      this.pdfContentWidth = pdfWidthRatio;
      this.showProjectDetails = true;
      this.$refs.html2Pdf.generatePdf();
    },
    handleProgress(event) {
      if (event === 100) {
        this.pdfContentWidth = 100;
        this.showProjectDetails = false;
      }
    },
    handleEplFileImport(event) {
      const file = event.target.files[0];

      if (file) {
        const reader = new FileReader();
        reader.onload = event => {
          this.base64PlanToImport = event.target.result;
          this.importBase64Plan();
        };
        reader.readAsText(file);
      }
    },
    importBase64Plan() {
      if (this.base64PlanToImport !== "") {
        let decodedPlan = "";
        try {
          decodedPlan = decode(this.base64PlanToImport);
          if (JSON.parse(decodedPlan).walls !== undefined) {
            window.localStorage.setItem("vuex", decodedPlan);
            location.reload();
          }
        } catch (error) {
          throw new Error(error);
        }
      }
    },
    copyBase64PlanToExport() {
      navigator.clipboard.writeText(this.base64PlanToExport);
      this.base64PlanCopied = true;
      setTimeout(() => (this.base64PlanCopied = false), 3000);
    },
    downloadPlan() {
      const link = document.createElement("a");
      const file = new Blob([this.base64PlanToExport], { type: "text/plain" });
      link.href = URL.createObjectURL(file);
      const fileName = `Esperio ontwerp_${this.projectName}_v${this.version}`;
      link.download = `${fileName}.epl`;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    setBase64PlanToExport(code) {
      this.base64PlanToExport = code;
    },
  },
  mounted() {
    if (process.env.NODE_ENV === "development") {
      document.title = `[DEV] Esperio Planner`;
    }

    window.addEventListener("mousemove", e => {
      if (this.isDraggingProjectDescription) {
        const diffX = e.clientX - mousePosX;
        const diffY = e.clientY - mousePosY;
        this.projectDescriptionX += diffX;
        this.projectDescriptionY += diffY;
      }
      mousePosX = e.clientX;
      mousePosY = e.clientY;
    });

    window.addEventListener("mouseup", () => {
      this.isDraggingProjectDescription = false;
    });
  },
};
</script>

<style>
.panel-list-move {
  transition: 0.15s;
}
</style>
